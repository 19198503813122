import { useMemo } from "react";
import {
  IHourIncrement,
  ITreeIncrement,
  ITreeIncrementsDates,
  ITreeIncrementsWithValues,
} from "types";
import { getDaysInMonth, getWeekday, getStringDateLight } from "utils/date";

const calculateIncrements = (increments: ITreeIncrement[], date: Date) => {
  const incrementsList: ITreeIncrementsWithValues = {};
  const incrementsResult: ITreeIncrementsDates = {};

  const realMonth = date.getMonth() + 1;
  const daysCount = getDaysInMonth(date.getMonth(), date.getFullYear());

  let daysWithIncrements = 0;
  let weekdaysIncrements = [0, 0, 0, 0, 0, 0, 0];
  const hoursIncrements: IHourIncrement[] = [];

  if (increments?.length) {
    increments.forEach((increment: ITreeIncrement) => {
      const incrementSplit = increment.createdAt.split("T");
      const date = incrementSplit[0];
      const time = incrementSplit[1];
      const hour = time.split(":")[0];

      if (incrementsList[date]?.value) {
        incrementsList[date].value += 1;
        incrementsList[date].hours[+hour] += 1;
      } else {
        // eslint-disable-next-line no-restricted-properties
        const hoursArray = Array.from({ length: 24 }, () => 0);
        hoursArray[+hour] = 1;

        incrementsList[date] = {
          value: 1,
          hours: hoursArray,
        };
      }
    });
  }

  // eslint-disable-next-line no-console
  console.log("incrementsList!!!", incrementsList);

  // eslint-disable-next-line no-console
  console.log("daysCount!!!", daysCount);

  for (let i = 1; i <= daysCount; i++) {
    // TODO: May be we need date with time zone?
    // const dateString = getStringDate(i, realMonth, date.getFullYear());
    const dateString = getStringDateLight(i, realMonth, date.getFullYear());

    // eslint-disable-next-line no-console
    console.log("dateString!!!", dateString);

    if (incrementsList[dateString]?.value) {
      incrementsResult[i] = incrementsList[dateString].value;

      // eslint-disable-next-line no-console
      console.log("incrementsList.value!!!", incrementsList[dateString].value);

      daysWithIncrements += 1;

      // eslint-disable-next-line no-console
      console.log("daysWithIncrements!!!", daysWithIncrements);

      weekdaysIncrements[
        getWeekday(i, date.getMonth(), date.getFullYear()).number
      ] += 1;

      incrementsList[dateString].hours.forEach((value, hour) => {
        value *= 5;
        if (value > 25) value = 25;

        hoursIncrements.push({
          x: i,
          y: hour,
          r: value,
        });
      });
    } else {
      incrementsResult[i] = 0;
    }
  }

  // Переносим воскресенье в конец (в js он первый день недели)
  const [firstWeekday, ...restWeekdays] = weekdaysIncrements;
  weekdaysIncrements = [...restWeekdays, firstWeekday];

  // eslint-disable-next-line no-console
  console.log("weekdaysIncrements", weekdaysIncrements);

  // eslint-disable-next-line no-console
  console.log("hoursIncrements", hoursIncrements);

  // eslint-disable-next-line no-console
  console.log("daysWithIncrements", daysWithIncrements);

  // eslint-disable-next-line no-console
  console.log("incrementsResult", incrementsResult);

  return {
    all: incrementsResult,
    weekdays: weekdaysIncrements,
    hours: hoursIncrements,
    daysCount: daysWithIncrements,
  };
};

export const useTreeIncrements = (increments: ITreeIncrement[], date: Date) => {
  return useMemo(
    () => calculateIncrements(increments, date),
    [increments, date]
  );
};
