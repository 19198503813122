import PageLayout from "components/PageLayout";
import Paragraph from "components/Paragraph";
import Title from "components/Title";

const Agreement = () => (
  <PageLayout breadcrumbs={[{ name: "Соглашение" }]}>
    <Title level="1" color="light">
      Соглашение
    </Title>
    <Paragraph color="light" align="justify">
      Данный проект выполнен ради тренировки в целях личного использования. Мы
      не несем ответственности за любые оставленные вами данные.
    </Paragraph>
  </PageLayout>
);

export default Agreement;
